@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond&family=Roboto&display=swap");

:root {
  --alden: #c7ae86;
  --aldendark: #9b8e6d;
}
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow: hidden;
  height: 100%;
}

body {
  margin: 0;
  font-family: "Cormorant Garamond", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

th,
td {
  padding: 10px;
  text-align: center;
}
